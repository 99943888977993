import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";

import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { downloadCsv, formatDate, getInvoiceId } from "../../../helperFunctions/utils";
import FilterComponent from "../../SharedComponents/FilterComponent";
import Filters from "./Filters";

const TableWrapper = styled.div`
  .rdt_TableCell > div {
    white-space: break-spaces;
  }
`;

export default function CustomizedPreviewTemplate({ id }) {
  const location = useLocation();
  const dispatch = useDispatch();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  // let { id } = params;
  // let id = "6503f974aad8eb231d26baf1";
  const { previewTemplate } = useSelector(
    (state) => state.templateData
  );

  const [previewTemplateData, setPreviewTemplateData] =
    useState(previewTemplate);
  const [templateTableData, setTemplateTableData] = useState([]);
  const [templateFilteredData, setTemplateFilteredData] = useState([]);
  const [templateTableColumns, setTemplateTableColumn] = useState([]);
  const [templateDetail, setTemplateDetail] = useState(null);
  const [filteredArray, setFilteredArray] = useState([]);
  const [oriArray, setOriArray] = useState([]);
  const [model, setModel] = useState("");

  const [tableName, setTableName] = useState("Preview");
  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  let keys = _.union(
    ...previewTemplateData?.map((object) => Object.keys(object))
  );

  keys = keys?.filter((o) => o !== "_id");

  const columns = keys?.map((o) => {
    return {
      name: o,
      selector: (row) => row[o],
    };
  });

  const tableData = (
    filteredArray?.length > 0 ? filteredArray : previewTemplateData
  )?.map((o) => {
    let obj = {};
    Object.entries(o).forEach((item) => {
      obj = {
        ...obj,
        [item[0]]:
          typeof item[1] == "boolean"
            ? JSON.stringify(item[1])
            : JSON.stringify(item[1]),
      };
    });
    return obj;
  });

  const getTemplate = async (id) => {
    let query = {};

    if (id !== 0) {
      query.id = id;
    } else {
      if (!location?.state?.table_name) return;
      query.table_name = location?.state?.table_name;
      query.columns = location?.state?.columns;
    }
    const { result, error } = await authorizedRequests({
      url: `collections/templates-data/?query=${JSON.stringify(query)}`,
      method: "get",
    });
    if (error) {
      toastify(error?.message ?? "Unknown error occured", "error");
      return;
    }
    setTemplateTableColumn(
      id !== 0 ? result?.data?.template?.columns : location?.state?.columns
      //previous-code with filter  /// id != 0 ? result?.data?.template?.columns.filter((d) => d.filter === true) : location?.state?.columns
    );

    const tempData = result?.data?.table_data;
    setTemplateTableData([...tempData]);
    setModel(result?.data?.template?.table_name);
    console.log({ result });
    setTableName(result.data.template.name);
    setTemplateDetail(result.data.template);
    return result.data;
  };

  useEffect(() => {
    if (id) {
      fetchTemplateFunc();
    }
  }, [id, location?.state?.table_name]);

  const fetchTemplateFunc = async() => {
    setFilteredArray([]);
    setOriArray([]);
    await getTemplate(id); 

    setTimeout(() => {
      if (tableSearch[fieldPathname] && tableSearch[fieldPathname][id] && tableSearch[fieldPathname][id]?.length > 0) {
        const btnSearchEl = document.getElementById("btn-search-template");
        if (btnSearchEl) {
          console.log("DEBUG", btnSearchEl);
          btnSearchEl.click();
        }
      }
    }, 1000);
  }

  const table_columns = templateTableColumns?.map((o) => {
    if (o?.refColumn && (o?.refColumn?.toLowerCase().includes("date") || o?.refColumn?.toLowerCase() === "dob")) {
      return {
        name: o.name,
        selector: (row) =>
        formatDate(new Date(row[o?.column][o?.refColumn])),
      };
    }
    if (o.ref) {
      return {
        name: (() => {
          return (
            <div className="d-flex align-items-center" id="ovly2">
              <span className="me-2">{o.name}</span>
              <FilterComponent
                withRef={o.refColumn}
                isNested
                type="search"
                array={filteredArray}
                oriArray={oriArray}
                setArray={setFilteredArray}
                value={o.column}
              />
            </div>
          );
        })(),
        selector: (row) => {
          let value = row;
          if (o.column.includes(".")) {
            const key = o.column;
            const keys = key.split(".");

            keys.forEach((k) => {
              value = value[k];
            });
          } else {
            value = value[o.column];
          }

          if (Array.isArray(value)) {
            return (
              <>
                {value.length > 0 &&
                  value.map((k) => {
                    let refValue = k;
                    if (o?.refColumn?.includes(".")) {
                      const key = o.refColumn;
                      const keys = key.split(".");

                      keys.forEach((refKey) => {
                        refValue = refValue[refKey];
                      });
                    } else {
                      refValue = refValue[o.refColumn];
                    }
                    if (Array.isArray(refValue)) {
                      {
                        refValue?.length > 0 &&
                          refValue?.map((r) => {
                            return (
                              <div className="text-center">
                                {typeof refValue !== "string"
                                  ? JSON.stringify(refValue)
                                  : refValue}
                              </div>
                            );
                          });
                      }
                    } else {
                      if (o.refColumn === "invoiceNo") {
                        return (
                          <div className="text-center">
                            {getInvoiceId(refValue)}
                          </div>
                        )
                      }
                      
                      return (
                        <div className="text-center">
                          {typeof refValue !== "string"
                            ? JSON.stringify(refValue)
                            : refValue}
                        </div>
                      );
                    }
                  })}
              </>
            );
          } else {
            let refValue = value;
            if (o?.refColumn?.includes(".")) {
              const key = o.refColumn;
              const keys = key.split(".");

              keys.forEach((refKey) => {
                refValue = refValue?.[refKey];
              });
            } else {
              refValue = refValue?.[o.refColumn];
            }
            if (Array.isArray(refValue)) {
              {
                refValue?.length > 0 &&
                  refValue?.map((r) => {
                    return (
                      <div className="text-center">
                        {typeof refValue !== "string"
                          ? JSON.stringify(refValue)
                          : refValue}
                      </div>
                    );
                  });
              }
            } else {
              return (
                <div className="text-center">
                  {typeof refValue !== "string"
                    ? JSON.stringify(refValue)
                    : refValue}
                </div>
              );
            }
          }
        },
        minWidth: "150px",
      };
    }
    if (o.column.toLowerCase().includes("date") || o.column.toLowerCase().includes("lastpayment")) {
      return {
        name: o.name,
        selector: (row) => formatDate(new Date(row[o.column])),
        minWidth: "150px",
      };
    }
    if (o.column.toLowerCase() === "paymentrecord") {
      return {
        name: o.name,
        selector: (row) => row[o.column]?.length > 0 ? row[o.column][row[o.column].length - 1]?.[o.item] : 0,
        minWidth: "150px",
      };
    }
    return {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">{o.name}</span>
          {!o.column.includes(".") ? (
            <FilterComponent
              dynamicFilter
              type="search"
              array={filteredArray}
              oriArray={oriArray}
              setArray={setFilteredArray}
              value={o.column}
            />
          ) : (
            <>
              <FilterComponent
                isNested
                dynamicFilter
                type="search"
                array={filteredArray}
                oriArray={oriArray}
                setArray={setFilteredArray}
                value={o.column}
              />
            </>
          )}
        </div>
      ),
      selector: (row) => {
        if (o.column.includes(".")) {
          const key = o.column;
          const keys = key.split(".");
          let value = row;

          keys?.forEach((k) => {
            if (value && k) {
              value = value[k] || null;
            }
          });
          if (Array.isArray(value)) {
            return (
              <>
                {value.length > 0 &&
                  value.map((k) => {
                    return (
                      <div className="text-center">
                        {typeof k !== "string" ? JSON.stringify(k) : k}
                      </div>
                    );
                  })}
              </>
            );
          }

          if (o.column.toLowerCase().includes("date") || o.column.toLowerCase().includes("dob")) {
            return formatDate(value);
          } 

          return value;
        }

        if (o.column.includes("invoiceNo")) {
          return getInvoiceId(row[o.column]);
        }

        if (Array.isArray(row[o.column])) {
          let arrStr = "";
          row[o.column].map((itemValue, index) => {
            if (row[o.column].length - 1 === index) {
              arrStr += itemValue;
            } else {
              arrStr += `${itemValue}, `;
            }

            return false;
          });

          return arrStr;
        }

        return typeof row[o.column] !== "string"
          ? JSON.stringify(row[o.column])
          : row[o.column];;
      },
      minWidth: "150px",
    };
  });

  useEffect(() => {
    if (
      location?.state?.startDate &&
      location?.state?.endDate &&
      templateTableData.length > 0
    ) {
      console.log(location?.state?.startDate);
      const tempArr = templateTableData.filter(
        (o) =>
          new Date(o.created_at).getTime() >
            location?.state?.startDate?.getTime() &&
          new Date(o.created_at)?.getTime() <
            location?.state?.endDate?.getTime()
      );
      setTemplateFilteredData([...tempArr]);
      setFilteredArray([...tempArr]);
      setOriArray([...tempArr]);
    } else {
      setTemplateFilteredData([...templateTableData]);
      setFilteredArray([...templateTableData]);
      setOriArray([...templateTableData]);
    }
  }, [location?.state?.startDate, location?.state?.endDate, templateTableData]);

  const handleExport = () => {
    const excelColumns = [];
    const excelRows = [];

    templateTableColumns.map((tbColumn) => {
      const columnName = tbColumn.name;
      let columnField = tbColumn.column;
      if (tbColumn.refColumn) {
        columnField += `.${tbColumn.refColumn}`;
      }

      excelColumns.push({
        name: columnName,
        field: columnField
      });

      return false;
    });

    [...filteredArray].map((o) => {
      const dataRow = {};
      excelColumns.map((column) => {
        let columnValue = null;
        const splitField = column.field.split(".");
        splitField.map((field) => {
          if (columnValue === null) {
            columnValue = o[field];
          } else {
            if (Array.isArray(columnValue)) {
              const refValues = [...columnValue];
              refValues.map((refValue, index) => {
                const indexValue = (refValues.length - 1 === index) ? refValue[field] : `${refValue[field]} ,`;
                if (typeof indexValue === "object") {
                  if (columnValue[field]) {
                    columnValue = columnValue[field];
                  } else {
                    columnValue = refValue[field];
                  }
                } else {
                  if (index === 0) {
                    columnValue = indexValue;
                  } else {
                    columnValue += indexValue;
                  }
                }
                return false;
              }) ;
            } else {
              columnValue = columnValue[field];
            }
          }

          return false;
        });

        if (column.name.toLowerCase().includes("date") ||
          column.name.toLowerCase().includes("last payment")) {
          dataRow[`${column.name}`] = formatDate(columnValue);
        } else if (column.name.includes("Invoice No")) {
          dataRow[`${column.name}`] = getInvoiceId(columnValue);
        } else {
          dataRow[`${column.name}`] = columnValue;
        }
        return false;
      });

      excelRows.push(dataRow);;
      return false;
    });

    downloadCsv(excelRows, `${tableName}`);
  }

  return (
    <>
      <div className="p-0">
        {/* <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                if (id != 0) navigate(-1);
                navigate("/add-template", {
                  state: location.state,
                });
              }}
            />
            {tableName}
          </h1>
        </div> */}
        {/* <div className="mb-3 w-100 d-flex justify-content-between px-4">
          <Button
            className="addItem ms-auto"
            onClick={() => handleExport()}
          >
            Export
          </Button>
        </div> */}
        <Filters
          id={id}
          columns={templateTableColumns}
          model={model}
          array={templateFilteredData}
          templateDetail={templateDetail}
          setArray={setFilteredArray}
          filteredArray={filteredArray}
          handleExport={handleExport}
        />
        <TableWrapper>
          <DataTable
            columns={table_columns}
            customStyles={customStyles}
            data={filteredArray}
            pagination
            conditionalRowStyles={conditionalRowStyles}
          />
        </TableWrapper>
        <div style={{ marginBottom: "20px" }}></div>
      </div>
    </>
  );
}
