import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TabGeneralInfo from "./TabGeneralInfo";
import TabAddressContact from "./TabAddressContact";
import TabEmergencyContact from "./TabEmergencyContact";
import TabMedicalInfo from "./TabMedicalInfo";
import TabMembershipInfo from "./TabMembershipInfo";
import { useSelector } from "react-redux";
import TabNurseNotes from "./TabNurseNotes";
import TabNutrionNotes from "./TabNutrionNotes";
import TabPhotoComparison from "./TabPhotoComparison";
import TabVisitHistory from "./TabVisitHistory";
import CaseFolder from "./CaseFolder";
import { toastify } from "../../../helperFunctions/toastify";
import {
  A_PS_AppointmentHistory,
  A_PS_BillingHistory,
  A_PS_CaseFolder,
  A_PS_EmergencyContact,
  A_PS_GeneralInfo,
  A_PS_GlanceView,
  A_PS_MedicalInfo,
  A_PS_MembershipInfo,
  A_PS_NurseNotes,
  A_PS_PhotoComparison,
  A_PS_PackageDropdown,
  A_PS_VisitHistory,
  I_PS_AppointmentHistory,
  I_PS_BillingHistory,
  I_PS_CaseFolder,
  I_PS_EmergencyContact,
  I_PS_GeneralInfo,
  I_PS_GlanceView,
  I_PS_MedicalInfo,
  I_PS_MembershipInfo,
  I_PS_NurseNotes,
  I_PS_PhotoComparison,
  I_PS_PackageDropdown,
  I_PS_VisitHistory,
  Flag,
} from "../../../assets/images";
import DataTable from "react-data-table-component";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { createMemos, getMemos, updateMemo } from "../../../apis/memo";
import { getBillingByPatient } from "../../../apis/billing";
import DeleteModal from "../../SharedComponents/DeleteModal";
import { updatePatientProfileImage } from "../../../apis/patients";
import loader from "../../../assets/gif/loader.gif";
import TabAppointmentHistory from "./TabAppointmentHistory";
import TabBillingHistory from "./TabBillingHistory";
import { setPatientData } from "../../../redux/slices/patientSlice";
import { useDispatch } from "react-redux";
import GlanceView from "./GlanceView";
import PackageDropdown from "./PackageDropdown";
import moment from "moment";
import { getEditBy } from "../../../apis/appointment";

export default function AddEditPatient() {
  const navigate = useNavigate();
  const patient = useSelector((state) => state.patientData.value);
  const [activePage, setActivePage] = useState("generalInfo");
  const [show, setShow] = useState(patient ? true : false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [description, setDescription] = useState("");
  const [isFlaged, setFlagged] = useState(false);
  const [memos, setMemos] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleting, setDeleting] = useState("");
  const [dp, setDp] = useState("/imgs/user.png");
  const [loading, setLoading] = useState(false);
  // billing
  const [oriBillings, setOriBillings] = useState([]);
  const [billings, setBillings] = useState([]);
  const [amountNeedToPay, setAmountNeedToPay] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    populateMemos();
  }, []);

  useEffect(() => {
    if (patient) {
      setDp(patient?.profilePhoto || "/imgs/user.png");
    }
  }, [patient]);

  const handleClose = () => {
    setShow3(false);
    setShow2(false);
    setShow(true);
    setDeleting("");
  };

  const populateMemos = async () => {
    let data = { patientId: patient?._id };
    const response = await getMemos(data);
    if (response?.status !== "Fail") {
      setMemos(response?.reverse());
    } else {
      toastify(response?.error, "error");
    }
  };

  const getHeading = () => {
    if (activePage === "generalInfo") return "General Info & Description";
    if (activePage === "addressContact") return "Address & Contact Info";
    if (activePage === "emergencyContact") return "Emergency Contact";
    if (activePage === "medicalInfo") return "Medical Info";
    if (activePage === "membershipInfo") return "Membership Info";
    if (activePage === "visitHistory") return "Visit History";
    if (activePage === "appointmentHistory") return "Appointment History";
    if (activePage === "billingHistory") return "Billing History";
    if (activePage === "nurseNotes") return "Therapist Notes";
    if (activePage === "nutrionNotes") return "Nutritionist Notes";
    if (activePage === "photoComparison") return "Photo Comparison";
    if (activePage === "packageDropdown") return "Package Dropdown";
    if (activePage === "glanceView") return "Glance View";
    if (activePage === "caseFolder") return "Case Folder";
  };

  const getSubheading = () => {
    if (activePage === "generalInfo") return "General Info & Description";
    if (activePage === "addressContact") return "Address & Contact Information";
    if (activePage === "emergencyContact")
      return "Emergency Contact Information";
    if (activePage === "medicalInfo") return "Medical Information";
    if (activePage === "membershipInfo") return "Membership";
    if (activePage === "nurseNotes") return null;
    if (activePage === "nutrionNotes") return null;
  };

  const getComponent = () => {
    if (activePage === "generalInfo")
      return (
        <TabGeneralInfo setPage={setActivePage} getEditData={getEditData} />
      );
    else if (activePage === "addressContact")
      return <TabAddressContact setPage={setActivePage} />;
    else if (activePage === "emergencyContact")
      return <TabEmergencyContact setPage={setActivePage} />;
    else if (activePage === "medicalInfo")
      return <TabMedicalInfo setPage={setActivePage} />;
    else if (activePage === "membershipInfo")
      return <TabMembershipInfo setPage={setActivePage} />;
    else if (activePage === "nurseNotes")
      return <TabNurseNotes setPage={setActivePage} />;
    else if (activePage === "nutrionNotes")
      return <TabNutrionNotes setPage={setActivePage} />;
    else if (activePage === "photoComparison")
      return <TabPhotoComparison setPage={setActivePage} />;
    else if (activePage === "visitHistory")
      return <TabVisitHistory setPage={setActivePage} />;
    else if (activePage === "appointmentHistory")
      return <TabAppointmentHistory setPage={setActivePage} />;
    else if (activePage === "billingHistory")
      return <TabBillingHistory setPage={setActivePage} />;
    else if (activePage === "packageDropdown")
      return (
        <PackageDropdown setPage={setActivePage} patientId={patient._id} />
      );
    else if (activePage === "glanceView")
      return <GlanceView state={{ id: patient._id }} setPage={setActivePage} />;
    else return <CaseFolder setPage={setActivePage} />;
  };

  const getStatusPayment = (o) => {
    const paidAmount =
      o?.paymentRecord && o?.paymentRecord?.length > 0
        ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
        : 0;
    const gstAmount = o?.gst;

    if (o?.isRefund) {
      if (o?.gst < 0) {
        return "Refund";
      } else if (o?.gst === 0) {
        return "Conversion";
      } else {
        return "Additional Charge";
      }
    }

    if (paidAmount < gstAmount && paidAmount !== 0) {
      return "Outstanding";
    }

    if (paidAmount > gstAmount) {
      return "Overpayment";
    }

    if (o?.isPaymentCompleted === true) {
      return "Paid";
    }

    return "Unpaid";
  };

  const getBillingList = async () => {
    const { result, error } = await getBillingByPatient(patient?._id);
    if (error) {
      if (error?.message === "No data Found") {
        setBillings([]);
      } else {
        toastify(error?.message, "error");
      }
      return;
    } else {
      let tempArr = result?.map((e) => {
        const loc = e?.branch?.location?.find((o) => o._id === e?.location);
        const packages = e?.package?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.package_id?.name,
            qty: o?.package_qty,
            price: o?.package_unit_price,
            discount: o?.package_discount,
            discountType: o?.package_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.package_price,
          };
        });
        const product = e?.product?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.product_id?.name,
            qty: o?.product_qty,
            price: o?.product_unit_price,
            discount: o?.product_discount,
            discountType: o?.product_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.product_price - o?.product_discount,
          };
        });
        const treatment = e?.treatment?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.service_id?.name,
            qty: o?.service_qty,
            price: o?.service_unit_price,
            discount: o?.service_discount,
            discountType: o?.service_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.service_price,
          };
        });
        const others = e?.others?.map((o) => {
          return {
            name: o?.description,
            qty: 1,
            price: o?.total_price,
            discount: 0,
            total_price: o?.total_price,
          };
        });

        let taxVal = 0;
        let taxs = [];
        e?.tax?.map((tax) => {
          if (taxVal !== 0) {
            taxVal =
              tax.typeValue == "Percentage"
                ? (tax.value / 100) * taxVal
                : tax.value + taxVal;
          } else {
            taxVal =
              tax.typeValue == "Percentage"
                ? (tax.value / 100) * e?.actualAmount
                : tax.value + e?.actualAmount;
          }

          taxs.push({
            ...tax,
            afterTaxValue: taxVal,
          });
        });

        const notesObj = {
          startTime: e?.session?.startTime,
          endTime: e?.session?.endTime,
          staff: e?.session?.staff?.map((o) => o?.username),
          soap: e?.session?.patientSOAP,
          location: loc,
          service: [...packages, ...product, ...treatment, ...others],
          remarks: e?.remarks,
          usePoints: e?.usePoints,
          promo: e?.promo,
          promoType: e?.promoType,
          promoDiscount: e?.promoDiscount,
          promoDiscountType: e?.promoDiscountType,
          paymentAdjustments: e?.paymentAdjustments,
          tax: e?.tax,
          promoDiscount:
            e?.promoType === "Amount"
              ? e?.promo
              : (e?.promo / 100) * e?.actualAmount,
          afterTaxValue: taxVal,
          gstAmount: e?.gst,
          actualAmount: e?.actualAmount,
          totalAmount: e?.totalAmount,
          paymentMethod: e?.paymentMethod?.name,
          installmentRecord: e?.paymentRecord ?? [],
        };

        const statusPayment = getStatusPayment(e);
        let classNameBtn = "";
        if (statusPayment === "Paid") {
          classNameBtn = "green-rounded-button";
        } else if (statusPayment === "Outstanding") {
          if (!e?.conversionId) {
            const payed = e?.paymentRecord?.[e?.paymentRecord?.length - 1]?.payed;
            setAmountNeedToPay((v) => parseFloat(v + ((Number(e.gst) - payed) || 0)).toFixed(2));
            classNameBtn = "yellow-rounded-button";
          } else {
            const refundPayment = result?.find(r => r._id === e.conversionId);
            const payed = e?.paymentRecord?.[e?.paymentRecord?.length - 1]?.payed;
            let refundAmount = 0;
            if (refundPayment) {
              refundAmount = refundPayment?.paymentRecord?.[e?.paymentRecord?.length - 1]?.payed;
            }

            const outstandingAmount = (Number(e.gst) - payed);
            if (outstandingAmount >= refundAmount) {
              setAmountNeedToPay((v) => parseFloat(v + ((outstandingAmount - Math.abs(refundAmount)))).toFixed(2));
            } else{
              setAmountNeedToPay((v) => parseFloat(v + (outstandingAmount)).toFixed(2));
            }
          }
        } else if (statusPayment === "Overpayment") {
          classNameBtn = "yellow-rounded-button";
        } else if (statusPayment === "Additional Charge") {
          classNameBtn = "additional-rounded-button"; 
        } else if (statusPayment === "Conversion") {
          classNameBtn = "conversion-rounded-button"; 
        } else if (statusPayment === "Unpaid") {
          const payed = e?.paymentRecord?.[e?.paymentRecord?.length - 1]?.currentPaidAmount;
          setAmountNeedToPay((v) => parseFloat(v + ((Number(e.gst) - payed) || 0)).toFixed(2));
          classNameBtn = "red-rounded-button";
        }

        return {
          date: new Date(e.created_at).toLocaleDateString(),
          notes: notesObj,
          _id: e._id,
          invoiceNo: e.invoiceNo,
          created_at: e?.created_at,
          remarks: e?.remarks,
          promo: e?.promo,
          promoType: e?.promoType,
          usePoints: e?.usePoints,
          actualAmount: e?.actualAmount,
          updated_at: e.updated_at,
          totalAmount: e.totalAmount,
          gstAmount: e?.gst,
          remark: e?.remarkDiscount,
          patient: e?.patient,
          isPaymentInInstallment: e?.isPaymentInInstallment,
          paymentRecord: e?.paymentRecord,
          paymentAdjustments: e?.paymentAdjustments,
          package: [...e?.package],
          others: [...e?.others],
          product: [...e?.product],
          treatment: [...e?.treatment],
          classNameBtn,
          status: statusPayment,
        };
      });
      tempArr = tempArr.reverse();
      setBillings(tempArr);
      setOriBillings(result.reverse());
    }
    // if (response?.status !== "Fail") {
    // 	// let _response = response?.filter(
    // 	// 	(e) => patientData?._id === e?.patient?._id,
    // 	// );

    // 	setBillings(_response);
    // 	console.log({ _response });
    // } else {

    // }
  };

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: patient?._id,
      type: "Patient",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
    getBillingList();
  }, []);

  const addMemo = async () => {
    if (!description) {
      missingPopup("Description");
    } else {
      let data = { description, isFlaged, patientId: patient?._id };

      const response = await createMemos(data);
      if (response?.status !== "Fail") {
        setShow2(false);
        populateMemos();
        toastify("Memo added successfully!", "success");
      } else {
        toastify(response?.message, "error");
      }
      setShow(true);
      setDescription("");
    }
  };

  const removeMemo = async () => {
    setShow3(false);
    const response = await updateMemo(deleting, { isDeleted: true });
    if (response.status !== "Fail") {
      populateMemos();
      toastify("Memo deleted!", "success");
    } else {
      toastify(response?.message, "error");
    }
    setShow(true);
  };

  const uploadImage = async (imgToUpload) => {
    setLoading(true);
    const response = await updatePatientProfileImage(patient?._id, imgToUpload);
    if (response && response?.status !== "Fail") {
      toastify("Image uploaded successfully.", "success");
      dispatch(setPatientData(response?.data));
      setDp(response?.data?.profilePhoto);
    } else {
      toastify(`Error uploading image, ${response.message}`, "error");
    }
    setLoading(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const columns = [
    {
      name: "Description",
      selector: (row) => <div className="text-center">{row.description}</div>,
      wrap: true,
    },

    // {
    //   name: "Created By",
    //   selector: (row) => {
    //     return row?.createdBy;
    //   },
    // },

    // {
    //   name: "Created On",
    //   selector: (row) => {
    //     return new Date(row?.createdAt)?.toLocaleDateString();
    //   },
    // },

    {
      name: "Flagged Status",
      selector: (row) => {
        return row.isFlaged && <img src={Flag} className="flag" />;
      },
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData.length === 0 ? memos : filteredData)?.map(
    (e, index) => {
      return {
        index: index,
        id: e._id,
        description: e.description,
        createdAt: e.created_at,
        createdBy: e.createdBy?.username,
        isFlaged: e.isFlaged,
        action: (
          <div className="d-flex">
            {/* <div
              className="mx-3 cursor-pointer icon-width"
              // onClick={() => {
              //   editConsent(e);
              // }}
            >
              <i className="fa fa-pen"></i>
            </div> */}
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                setShow(false);
                setShow2(false);
                setDeleting(e._id);
                setShow3(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between patient-wrapper">
        <div className="col-9">
          <div
            className="mainHeading px-4"
            style={activePage == "packageDropdown" ? { paddingBottom: 0 } : {}}
          >
            <div>
              <h1>
                <i
                  className="fa fa-arrow-left cursor-pointer"
                  onClick={() => navigate(-1)}
                ></i>
                Patient Profile &nbsp;
                <span style={{ fontWeight: 400 }}>({getHeading()})</span>
              </h1>
              {editLogs && (
                <div style={{ marginLeft: "5px" }}>
                  {" "}
                  By {editLogs?.userName} At{" "}
                  {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
                </div>
              )}
            </div>
          </div>
          <div className="pb-4 px-4">
            <div className="content">
              <div
                className={`${
                  activePage !== "packageDropdown" ? "addEdit" : ""
                }`}
                style={{}}
              >
                {activePage !== "nurseNotes" && (
                  <p className="sub-heading">{getSubheading()}</p>
                )}
                <div className="row">{getComponent()}</div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="mt-5"
          size="lg"
          show={show}
          onHide={() => {
            setShow(false);
            setFilteredData([]);
          }}
          centered
        >
          <Modal.Header closeButton className="pb-0" style={{ borderWidth: 0 }}>
            <div className="d-flex w-100 justify-content-center p-0 alert-heading">
              Patient Alert
            </div>
          </Modal.Header>
          <Modal.Body style={{ overflowY: "scroll", maxHeight: 400 }}>
            <div>
              <div className="bold mt-2">Patient Name</div>
              <div>{patient?.patientProfile?.fullName}</div>
              <div className="bold mt-2">Gender</div>
              <div>{patient?.patientProfile?.gender}</div>
              <div className="bold mt-2">National ID</div>
              <div>{patient?.patientProfile?.nationID}</div>
            </div>

            <Button
              className="my-3 px-4 memo-button"
              onClick={() => {
                setShow(false);
                setShow2(true);
              }}
            >
              + Add Memo
            </Button>

            <div className="d-flex align-items-center mb-4">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilteredData([...data.filter((e) => e.isFlaged)]);
                  } else {
                    setFilteredData([]);
                  }
                }}
              />
              <label className="flag-check m-0">On show flagged</label>
            </div>
            <div>
              <DataTable
                columns={columns}
                customStyles={customStyles}
                data={data || []}
                pagination
                // dense
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="sm"
          centered
          show={show2}
          onHide={() => {
            setShow2(false);
            setDescription("");
          }}
        >
          <Modal.Body>
            <div>
              <Form.Group className="mb-3">
                <Form.Label className="color-black">Description *</Form.Label>
                <InputGroup className="">
                  <Form.Control
                    placeholder="Write..."
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </InputGroup>
              </Form.Group>
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setFlagged(true);
                  } else {
                    setFlagged(false);
                  }
                }}
                checked={isFlaged}
              />
              <label className="flag-check mb-4-0">Flagged</label>

              <Button className="memo-save-button mt-3" onClick={addMemo}>
                Save
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <DeleteModal
          content="Confirm Memo Deletion?"
          onYes={removeMemo}
          onNo={handleClose}
          show={show3}
        />

        <div className="addSecondSidebar col-3">
          <>
            <div className="patientImg">
              <p>{patient?.accountNumber}</p>

              <label className="myLabel d-flex justify-content-center">
                <input
                  type="file"
                  required
                  accept={[".png", ".jpg"]}
                  onChange={(e) => {
                    uploadImage(e.target.files[0]);
                  }}
                  id="upload-photo"
                />
                <div className="dp-div">
                  <img
                    className="cursor-pointer"
                    style={{
                      width: loading
                        ? "80%"
                        : dp === "/imgs/user.png"
                        ? "50px"
                        : "100%",
                      // display: loading ? "none" : "flex",
                    }}
                    alt="user"
                    key={loading ? loader : dp}
                    src={loading ? loader : dp}
                  />
                </div>
              </label>

              <h5 className="name">{patient?.patientProfile?.fullName}</h5>
            </div>

            <div className="alertBtn">
              <Button onClick={() => setShow(true)}>Patient Alert</Button>
            </div>

            <div className="addressDetail">
              <div className="detailPadding">
                <i className="fa fa-envelope"></i>
                <h6>
                  {patient?.patientProfile?.email
                    ? patient?.patientProfile?.email
                    : "-"}
                </h6>
              </div>
              <div className="detailPadding">
                <i className="fa fa-phone"></i>
                <h6>
                  {patient?.patientProfile?.contactNo
                    ? patient?.patientProfile?.contactNo
                    : "-"}
                </h6>
              </div>
              <div className="detailPadding">
                <i className="fa fa-book"></i>
                <h6>
                  {patient?.patientProfile?.fullName
                    ? patient?.patientProfile?.fullName
                    : "-"}
                </h6>
              </div>
              <div className="detailPadding">
                <i className="fa fa-map-marker"></i>
                <h6>
                  {patient?.patientProfile?.country &&
                  patient?.patientProfile?.address
                    ? `${patient?.patientProfile?.country?.name}, ${patient?.patientProfile?.address}`
                    : "-"}
                </h6>
              </div>
              <div className="detailPadding">
                <i className="fa fa-money-bill"></i>
                <p style={{ color: amountNeedToPay > 0 && "red" }}>
                  ${amountNeedToPay} Unpaid
                </p>
              </div>
            </div>
          </>

          <div className="patientInfo">
            <div className="sideBars">
              <ul>
                <div
                  onClick={() => {
                    setActivePage("generalInfo");
                    scrollToTop();
                  }}
                >
                  <li className={activePage === "generalInfo" ? "active" : ""}>
                    <div className="d-flex align-items-center">
                      {activePage === "generalInfo" ? (
                        <img className="pt-icon" src={A_PS_GeneralInfo} />
                      ) : (
                        <img className="pt-icon" src={I_PS_GeneralInfo} />
                      )}
                      General Info & Description
                    </div>
                  </li>
                </div>

                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("emergencyContact");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li
                    className={
                      activePage === "emergencyContact" ? "active" : ""
                    }
                  >
                    <div className="d-flex align-items-center">
                      {activePage === "emergencyContact" ? (
                        <img className="pt-icon" src={A_PS_EmergencyContact} />
                      ) : (
                        <img className="pt-icon" src={I_PS_EmergencyContact} />
                      )}
                      Emergency Contact
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("medicalInfo");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li className={activePage === "medicalInfo" ? "active" : ""}>
                    <div className="d-flex align-items-center">
                      {activePage === "medicalInfo" ? (
                        <img className="pt-icon" src={A_PS_MedicalInfo} />
                      ) : (
                        <img className="pt-icon" src={I_PS_MedicalInfo} />
                      )}
                      Medical Info
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("membershipInfo");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li
                    className={activePage === "membershipInfo" ? "active" : ""}
                  >
                    <div className="d-flex align-items-center">
                      {activePage === "membershipInfo" ? (
                        <img className="pt-icon" src={A_PS_MembershipInfo} />
                      ) : (
                        <img className="pt-icon" src={I_PS_MembershipInfo} />
                      )}
                      Membership Info
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("packageDropdown");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li
                    className={activePage === "packageDropdown" ? "active" : ""}
                  >
                    <div className="d-flex align-items-center">
                      {activePage === "packageDropdown" ? (
                        <img className="pt-icon" src={A_PS_PackageDropdown} />
                      ) : (
                        <img className="pt-icon" src={I_PS_PackageDropdown} />
                      )}
                      Package Dropdown
                    </div>
                  </li>
                </div>

                {/* <div
									onClick={() => {
										if (patient) {
											setActivePage("visitHistory");
											scrollToTop();
										} else {
											toastify("Create Patient First");
										}
									}}
								>
									<li
										className={
											activePage === "visitHistory"
												? "active"
												: ""
										}
									>
										<div className="d-flex align-items-center">
											{activePage === "visitHistory" ? (
												<img
													className="pt-icon"
													src={A_PS_VisitHistory}
												/>
											) : (
												<img
													className="pt-icon"
													src={I_PS_VisitHistory}
												/>
											)}
											Visit History
										</div>
									</li>
								</div> */}
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("appointmentHistory");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li
                    className={
                      activePage === "appointmentHistory" ? "active" : ""
                    }
                  >
                    <div className="d-flex align-items-center">
                      {activePage === "appointmentHistory" ? (
                        <img
                          className="pt-icon"
                          src={A_PS_AppointmentHistory}
                        />
                      ) : (
                        <img
                          className="pt-icon"
                          src={I_PS_AppointmentHistory}
                        />
                      )}
                      Appointment History
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("billingHistory");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li
                    className={activePage === "billingHistory" ? "active" : ""}
                  >
                    <div className="d-flex align-items-center">
                      {activePage === "billingHistory" ? (
                        <img className="pt-icon" src={A_PS_BillingHistory} />
                      ) : (
                        <img className="pt-icon" src={I_PS_BillingHistory} />
                      )}
                      Billing History
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      scrollToTop();
                      setActivePage("glanceView");
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li className={activePage === "glanceView" ? "active" : ""}>
                    <div className="d-flex align-items-center">
                      {activePage === "glanceView" ? (
                        <img className="pt-icon" src={A_PS_GlanceView} />
                      ) : (
                        <img className="pt-icon" src={I_PS_GlanceView} />
                      )}
                      Glance View
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("nurseNotes");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li className={activePage === "nurseNotes" ? "active" : ""}>
                    <div className="d-flex align-items-center">
                      {activePage === "nurseNotes" ? (
                        <img className="pt-icon" src={A_PS_NurseNotes} />
                      ) : (
                        <img className="pt-icon" src={I_PS_NurseNotes} />
                      )}
                      Therapist Notes
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("nutrionNotes");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li className={activePage === "nutrionNotes" ? "active" : ""}>
                    <div className="d-flex align-items-center">
                      {activePage === "nutrionNotes" ? (
                        <img className="pt-icon" src={A_PS_NurseNotes} />
                      ) : (
                        <img className="pt-icon" src={I_PS_NurseNotes} />
                      )}
                      Nutritionist Notes
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("photoComparison");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li className={activePage === "photoComparison" ? "active" : ""}>
                    <div className="d-flex align-items-center">
                      {activePage === "photoComparison" ? (
                        <img className="pt-icon" src={A_PS_PhotoComparison} />
                      ) : (
                        <img className="pt-icon" src={I_PS_PhotoComparison} />
                      )}
                      Photo Comparison
                    </div>
                  </li>
                </div>
                <div
                  onClick={() => {
                    if (patient) {
                      setActivePage("caseFolder");
                      scrollToTop();
                    } else {
                      toastify("Create Patient First");
                    }
                  }}
                >
                  <li className={activePage === "caseFolder" ? "active" : ""}>
                    <div className="d-flex align-items-center">
                      {activePage === "caseFolder" ? (
                        <img className="pt-icon" src={A_PS_CaseFolder} />
                      ) : (
                        <img className="pt-icon" src={I_PS_CaseFolder} />
                      )}
                      Case Folder
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
