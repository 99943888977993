import React, { useState, useEffect, useMemo } from "react";
import { Button, Form, InputGroup, Collapse, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import DateInput from "../../SharedComponents/DateInput";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { getInvoiceId } from "../../../helperFunctions/utils";

import { resetValue } from "../../../redux/slices/StaffFormsSlices";
import { getPaymentMethodList } from "../../../apis/paymentMethod";

import { PaymentWrapper } from "./styled";

const customStyles = {
  cells: {
    style: {
      paddingTop: "4px",
      paddingBottom: "4px"
    },
  },
};

const AddPayment = () => {
	const numberFormatter = Intl.NumberFormat('en-US');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const billing = location.state;
  const patient = billing?.patient;

  const [items, setItems] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [showPoint, setShowPoint] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [pointsExpiry, setPointsExpiry] = useState();
  const [membershipCategories, setMembershiCategories] = useState([]);

  const [paymentRecord, setPaymentRecord] = useState(billing?.paymentRecord ?? [
    {
      totalAmount: billing?.gstAmount,
      payed: 0,
      remaining: billing?.gstAmount,
      paymentMethod: "",
      currentPaidAmount: 0,
      date: new Date(),
      remarks: ""
    }
  ]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [usePoint, setUsePoint] = useState(0);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [optionalAmount, setOptionalAmount] = useState(billing?.isPaymentInInstallment);
  const [optionPaymentMethods, setOptionPaymentMethods] = useState([]);
  const [collapseIdx, setCollapseIdx] = useState(0);
  const [setting, setSettings] = useState({});
  const [tax, setTax] = useState(null);

  const getMembershipCategories = async () => {
    const { result, error } = await authorizedRequests({
      url: "membershipCategory/listOfMembershipCategory",
      method: "get",
    });
    setMembershiCategories(result?.data);
  };

  const getTax = async () => {
    const { result, error } = await authorizedRequests({
      url: "taxSetting/listOfTaxSetting",
      method: "get",
    });
    if (error) {
      return;
    }
    const tempArr = result?.data?.map((o) => {
      return {
        id: o._id,
        name: o.name,
        type: o.typeValue,
        value: o._id,
        taxValue: o.value,
        label: o.name,
      };
    });

    const getGST = tempArr.find((o) => o?.name?.toLowerCase() == "gst");
    if (getGST) {
      setTax(getGST);
    }
  };

  useEffect(() => {
    getPaymentMethodList()
    .then((res) => {
      setOptionPaymentMethods(res.result?.data ?? []);
    })

    authorizedRequests({
			url: "membershipSetting/listOfMembershipSetting",
			method: "get",
		})
		.then((res) => {
			setSettings({
				value: res.result?.data[0]?.point,
				conversion: res.result?.data[0]?.conversion,
				memberExpiry: res.result?.data[0]?.expForMember,
				nonMemberExpiry: res.result?.data[0]?.expForNonMember,
				isDouble: res.result?.data[0]?.isDouble,
				rewardPoint: res.result?.data[0]?.rewardPoint,
				rewardAmount: res.result?.data[0]?.rewardAmount,
			});
		});

    getMembershipCategories();
    getTax();
  }, []);

  const getTotalPaidAmount = () => {
    if (billing?.isPayed === true) {
      return billing?.gstAmount;
    }

    return paymentRecord[paymentRecord.length - 1]?.payed ?? 0;
  }
  
  const getRemainingAmount = () => {
    if (billing?.isPayed === true) {
      return 0;
    }

    const totalPaidAmount = getTotalPaidAmount();

    if (billing?.gstAmount === totalPaidAmount) {
      const amount = paymentRecord[paymentRecord.length - 1]?.remaining ?? billing?.gstAmount;
      return parseFloat((amount ? amount : 0).toFixed(2));
    } else {
      if (billing?.gstAmount < 0 && totalPaidAmount < 0) {
        return parseFloat(((Math.round(billing?.gstAmount) - Math.round(totalPaidAmount)) * -1).toFixed(2));
      } else {
        return parseFloat((billing?.gstAmount - totalPaidAmount).toFixed(2));
      }
    }
  }
  
  useEffect(() => {
    const products = billing?.product?.map((x => (
      {
        type: "Product",
        name: x.name ? x.name : x.product_id?.description,
        price: x.product_unit_price,
        cost: x.product_unit_cost,
        qty: x.product_qty,
        total_price: x.product_price,
        discount: x.product_discount,
        discountType: x.product_discountType,
        promoName: x.promoName,
        promoDiscountType: x.promoDiscountType,
        promoDiscountValue: x.promoDiscountValue
      }
    ))) ?? [];
    const treatments = billing?.treatment?.map((x => (
      {
        type: "Treatment",
        name: x.name ? x.name : x.service_id?.name,
        price: x.service_unit_price,
        qty: x.service_qty,
        total_price: x.service_price,
        discount: x.service_discount,
        discountType: x.service_discountType,
        promoName: x.promoName,
        promoDiscountType: x.promoDiscountType,
        promoDiscountValue: x.promoDiscountValue
      }
    ))) ?? [];
    const packages = billing?.package?.map((x => (
      {
        type: "Package",
        name: x.name ? x.name : x.package_id?.name,
        price: x.package_unit_price,
        qty: x.package_qty,
        total_price: x.package_price,
        discount: x.package_discount,
        discountType: x.package_discountType,
        promoName: x.promoName,
        promoDiscountType: x.promoDiscountType,
        promoDiscountValue: x.promoDiscountValue
      }
    ))) ?? [];

    const others = billing?.others?.map((x => (
      {
        type: "Other",
        name: x.description,
        qty: "-",
        total_price: x.total_price,
        discount: 0,
        promoName: "",
        promoDiscountValue: 0
      }
    ))) ?? [];

    setItems([
      ...packages,
      ...products,
      ...treatments,
      ...others
    ]);
    if (billing?.paymentRecord.length > 0) {
      setPaymentRecord(billing?.paymentRecord);
    } else {
      setPaymentRecord([
        {
          totalAmount: billing?.gstAmount,
          payed: 0,
          remaining: billing?.gstAmount,
          paymentMethod: "",
          currentPaidAmount: 0,
          date: new Date(),
          remarks: ""
        }
      ])
    }
    setRemarks(billing?.remarks ?? "");
    setOptionalAmount(billing?.isPaymentInInstallment ?? false);
    setUsePoint(billing?.usePoints);
  }, [billing]);

  useMemo(() => {
    const today = new Date();
    if (
      patient &&
      patient?.isMember
      // selectedOptPatient?.patientProfile?.dob
    ) {
      let totalPaidAmount = 0;
      let totalPaymentAmount = billing?.totalAmount;
      let totalPreviousPaidAmount = billing?.lastPaidAmount;
      let totalPreviousAmount = billing?.lastTotalAmount;
      if (paymentRecord?.length > 0) {
        totalPaidAmount = paymentRecord[paymentRecord.length - 1]?.payed ?? 0;
      }

      let total = totalPaidAmount - totalPreviousPaidAmount;
      if (total !== 0) {
        if (totalPaidAmount === billing?.gstAmount) {
          if (billing?.isConversion === true) {
            total = total - (billing?.gstAmount - billing?.totalAmount);
          } else {
            if (((total - (billing?.gstAmount - billing?.totalAmount) > 0) && billing?.isPaymentCompleted) || (total - (billing?.gstAmount - billing?.totalAmount) < 0)) {
              const taxValue = tax.taxValue;
              total = total / (1 + (taxValue / 100));
            } else {
              total = total - (billing?.gstAmount - billing?.totalAmount);
            }
            // if () {
            //   total = totalPaymentAmount - total
            // } else {
            //   total = total - (billing?.gstAmount - billing?.totalAmount);
            // }
          }
        } else if (totalPaidAmount > billing?.gstAmount) {
          total = total - Math.abs(getRemainingAmount()) - (billing?.gstAmount - billing?.totalAmount);
        } else if ((Math.abs(total) + Math.abs(totalPaidAmount)) === Math.abs(billing?.gstAmount)) {
          total = total + (billing?.gstAmount - billing?.totalAmount);
        }
      }

      const patientDOB = new Date(patient?.patientProfile?.dob);
      if (setting.rewardAmount && setting.rewardPoint) {
        let tempPoint = parseFloat((total / setting.rewardAmount).toFixed(1)) * setting.rewardPoint;

        if (
          setting.isDouble &&
          patientDOB !== "Invalid Date" &&
          today.getMonth() === patientDOB.getMonth() &&
          today.getDate() === patientDOB.getDate()
        ) {
          tempPoint = tempPoint * 2;
        }
        setRewardPoints(tempPoint);
      }
    }
  }, [setting.rewardPoint, setting.isDouble, patient, billing, paymentRecord]);

  useMemo(() => {
    if (
      patient &&
      membershipCategories.length > 0
    ) {
      setPointsExpiry(setting.memberExpiry);
      // const patMembershitp = membershipCategories.find(
      //   (o) => o.startFrom <= patient?.points ?? 0
      // );

      // console.log("DEBUG", patMembershitp, membershipCategories, setting)
      // if (patMembershitp?._id) {
      //   setPointsExpiry(setting.memberExpiry);
      // } else {
      //   setPointsExpiry(setting.nonMemberExpiry);
      // }
    }
  }, [patient, membershipCategories, setting]);

  useMemo(() => {
    if (pointsExpiry) {
      setExpiryDate(
        new Date(new Date().setMonth(new Date().getMonth() + pointsExpiry))
      );
    }
  }, [pointsExpiry]);

  const item_column = [
		{
			name: "Item Description",
      selector: (row) => row.promoName ? `${row.name} (${row.promoName})` : row.name,
			wrap: true,
		},
		{
			name: "Qty",
			selector: (row) => row.qty,
			wrap: true,
    },
    {
      name: "Promo",
			selector: (row) => row.promoDiscountType === "Percentage" ? `${row.promoDiscountValue}%` : `$${row.promoDiscountValue ?? 0}`,
			wrap: true,
    },
    {
			name: "Discount",
			selector: (row) => row.discountType === "Percentage" ? `${row.discount}%` : `$${row.discount}`,
			wrap: true,
		},
		{
			name: "Amount",
			selector: (row) => `$${row.total_price}`,
			wrap: true,
		},
  ];

  const submitForm = async () => {
		// if (+installmentAmount === 0 && optionalAmount === true) {
		// 	return toastify(
		// 		"Amount you entered cannot be zero",
		// 		"error"
		// 	)
		// }
		// if (+installmentAmount > billing?.gstAmount) {
		// 	return toastify(
		// 		"Amount you entered is greater than remaining amount",
		// 		"error"
		// 	)
    // }
    const missingPaymentMethod = paymentRecord.find(p => p.paymentMethod === "" || !p.paymentMethod);
    if (missingPaymentMethod) {
      return toastify(
        "Please select the payment method",
        "error"
      )
    }
    
    let payload = {};
    if (optionalAmount) {
      const paymentRecord = billing.paymentRecord?.[billing.paymentRecord?.length - 1];
      payload = {
        totalAmount: paymentRecord?.totalAmount ?? billing?.gstAmount,
        payed: (paymentRecord?.payed ?? 0) + Math.abs(Number(installmentAmount)),
        remaining: ((paymentRecord?.remaining ?? billing?.gstAmount) - (Number(installmentAmount))).toFixed(2),
        paymentMethod: paymentMethodName,
        currentPaidAmount: Number(installmentAmount),
      };
    } else {
      payload = {
        totalAmount: billing?.gstAmount,
        payed: billing?.gstAmount,
        remaining: 0,
        paymentMethod: paymentMethodName,
        currentPaidAmount: billing?.gstAmount,
      }; 
    }

    const { result, error } = await authorizedRequests({
      url: "payment/updatePaidBilling",
      method: "post",
      data: {
        dataId: billing.dataId,
        remarks: remarks,
        isPaymentInInstallment: optionalAmount,
        paymentRecord: paymentRecord,
        rewardPoints: rewardPoints,
        // installmentRecord: payload,
      },
    });

    if (result) {
      toastify("Payment successfully updated", "success");
    }

		dispatch(resetValue("billingForm"));
		navigate("/billing");
		return;
  };
  
  const addPaymentRecord = () => {
    const lastRecord = paymentRecord[paymentRecord.length - 1];
    setPaymentRecord(() => {
      return [
        ...paymentRecord, 
        {
          totalAmount: paymentRecord.length > 0 ? lastRecord.totalAmount : billing?.gstAmount,
          payed: paymentRecord.length > 0 ? lastRecord.payed : 0,
          remaining: paymentRecord.length > 0 ? lastRecord.remaining : billing?.gstAmount,
          paymentMethod: "",
          currentPaidAmount: 0,
          date: new Date(),
          remarks: ""
        }
      ]
    })
  }

  const removePaymentRecord = (index) => {
    setPaymentRecord(() => {
      const updatedRecord = [...paymentRecord];
      updatedRecord.splice(index, 1);

      let accPayed = 0;
      updatedRecord.map((record) => {
        accPayed += Number(record.currentPaidAmount);
        record.payed = accPayed;
        record.remaining = Number(record.totalAmount) - Number(record.payed);
      });

      return updatedRecord;
    });

    if (index !== 0) {
      setCollapseIdx(index - 1);
    }
  }

  const changePaymentRecord = (index, field, value) => {
    setPaymentRecord(() => {
      const updatedRecord = [...paymentRecord];
      updatedRecord[index][field] = value;

      let accPayed = 0;
      updatedRecord.map((record) => {
        accPayed += Number(record.currentPaidAmount === "-" ? 0 : record.currentPaidAmount); 
        record.totalAmount = billing?.gstAmount;
        record.payed = accPayed;
        record.remaining = parseFloat((Number(record.totalAmount) - Number(record.payed))?.toFixed(2));
      });

      return updatedRecord;
    })
  }

  return (
    <div className="container">
      <div className="mainHeading px-0">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <span className="400-28-32">
            {location?.state?.formStatus} List Invoice
          </span>
        </h1>
      </div>
      <PaymentWrapper className="p-3">
        <div className="patient-detail">
          <div>
            <div className="highlighted-container d-flex px-3 p-2 col-10 mb-2">
              <div className="font-500-20 py-1">
                <b>Patient Name</b>
              </div>
              <div className="align-self-center ms-auto font-500-20">
                {patient?.patientProfile?.fullName}
              </div>
            </div>
            <div className="highlighted-container d-flex px-3 p-2 col-10 mb-2">
              <div className="font-500-20 py-1">
                <b>Invoice No</b>
              </div>
              <div className="align-self-center ms-auto font-500-20">
                {getInvoiceId(billing?.invoiceNo)}
              </div>
            </div>
            <div className="highlighted-container d-flex px-3 p-2 col-10 mb-2">
              <div className="font-500-20 py-1">
                <b>Invoice Date</b>
              </div>
              <div className="align-self-center ms-auto font-500-20">
                {billing?.invoiceDate}
              </div>
            </div>
          </div>
        </div>
        <div className="highlighted-container mt-4">
          <div className="item-wrapper">
            <div>
              <div className="p-4" style={{minHeight: "652px", borderRight: "1px solid #ddd"}}>
                <p className="font-500-20">
                  <b>Invoice Items</b>
                </p>

                <DataTable
                  data={items}
                  columns={item_column}
                  customStyles={customStyles}
                />

                <div style={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                  <div style={{width: "450px"}}>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Total Items</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(billing?.totalItemAmount ?? 0)}</b>
                      </div>
                    </div>
                    {
                      billing?.paymentAdjustments?.map((adjustment, index) => (
                        <div key={index} className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                          <div className="font-500-20 py-1">
                            {
                              adjustment?.discountType === "Percentage" ? (
                                <b>{adjustment.name} {`(${adjustment?.discount ?? 0}%)`}</b>
                              ) : (
                                <b>{adjustment.name}</b>
                              )
                            }
                          </div>
                          <div className="align-self-center ms-auto font-500-20">
                            {
                              adjustment?.discountType === "Percentage" ? (
                                `($${numberFormatter.format(Math.abs(((adjustment?.discount ?? 0) / 100) * (billing?.totalItemAmount ?? 0)))})`
                              ) : (
                                `($${numberFormatter.format(Math.abs(adjustment?.discount ?? 0))})`
                              )
                            }
                          </div>
                        </div>
                      ))
                    }

                    <div className="divider"></div>

                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Sub Total</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(billing?.subtotalAmount ?? 0)}</b>
                      </div>
                    </div>


                    {
                      billing?.promo !== 0 && (
                        <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                          <div className="font-500-20 py-1">
                            {
                              billing?.promoType === "Percentage" ? (
                                <b>Discount {`(${billing?.promo ?? 0}%)`}</b>
                              ) : (
                                <b>Discount</b>
                              )
                            }
                          </div>
                          <div className="align-self-center ms-auto font-500-20">
                            {
                              billing?.promoType === "Percentage" ? (
                                `($${numberFormatter.format(((billing?.promo ?? 0) / 100) * (billing?.subtotalAmount ?? 0))})`
                              ) : (
                                `($${numberFormatter.format(billing?.promo ?? 0)})`
                              )
                            
                            }
                          </div>
                        </div>
                      )
                    }

                    {
                      billing?.usePoints !== 0 && (
                        <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                          <div className="font-500-20 py-1">
                            <b>Used Point ({usePoint})</b>
                          </div>
                          <div className="align-self-center ms-auto font-500-20">
                            (${numberFormatter.format(billing?.usePointAmount) || 0})
                          </div>
                        </div>
                      )
                    }

                    <div className="divider"></div>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>GST</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        ${numberFormatter.format((billing?.gstAmount ?? 0) - (billing?.totalAmount ?? 0))}
                      </div>
                    </div>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Total GST</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(billing?.gstAmount ?? 0)}</b>
                      </div>
                    </div>

                    <div className="divider"></div>

                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Total Paid Amount</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(getTotalPaidAmount())}</b>
                      </div>
                    </div>
                    {/* <div style={{ paddingLeft: "25px" }}>
                      {
                        billing?.paymentRecord?.map((paidRecord, index) => (
                          <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2" key={index}>
                            <div className=" font-500-20 py-1" style={{ fontSize: "16px" }}>
                              {
                                paidRecord.date ? (
                                  <span>
                                    Paid Amount ({moment(paidRecord.date).format("DD/MMM/YYYY")})
                                  </span>
                                ) : (
                                  <span>Paid Amount {index + 1}</span>
                                )
                              }
                            </div>
                            <div
                              className="align-self-center ms-auto font-500-20"
                              style={{ fontWeight: "500", fontSize: "16px" }}
                            >
                              ${paidRecord?.currentPaidAmount || 0}
                            </div>
                          </div>
                        ))
                      }
                    </div> */}
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Remaining Amount</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(getRemainingAmount())}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              {/* <div>
                <Form.Label className="black-label-text" style={{marginRight: "20px"}}>
                  Want to pay in installments?
                </Form.Label>
                <Form.Check
                  style={{position: "relative", top: "2px"}}
                  inline
                  type={"checkbox"}
                  value={optionalAmount}
                  onClick={() => {
                    setOptionalAmount(!optionalAmount)
                  }}
                />
              </div> */}

              <div style={{textAlign: "right"}}>
                <Button
                  className="addItem mb-4"
                  onClick={() => addPaymentRecord()}
                  style={{ width: "200px", height: "50px", display: "inline-block" }}
                >
                  Add Paid Amount
                </Button>
              </div>

              {
                paymentRecord?.map((paidRecord, index) => {
                  let paymentRecordId = paidRecord._id;
                  let getDate = new Date(paidRecord.date);
                  getDate.setDate(getDate.getDate() + 1);
                  let currentDate = new Date();
                  let isPast = getDate < currentDate;
                  let disabled = paymentRecordId && isPast;
                  return (
                    <div key={index} aria-controls={`collapse${index}`}  aria-expanded={index === collapseIdx} style={{marginBottom: "20px"}}>
                      <div className="record-box" onClick={() => setCollapseIdx(index)}>
                        Paid Amount ({moment(paidRecord.date).format("DD/MMM/YYYY")})

                        <i className="fa fa-close" onClick={() => removePaymentRecord(index)}></i>
                      </div>
                      <Collapse in={index === collapseIdx}>
                        <div id={`collapse${index}`} style={{marginTop: "20px"}}>
                          <div>
                            <Form.Label className="black-label-text">
                              Payment Method
                            </Form.Label>
                            <div>
                              {optionPaymentMethods.length > 0 &&
                                optionPaymentMethods.map((meth) => {
                                  return (
                                    <>
                                      <Form.Check
                                        inline
                                        label={meth.name}
                                        type={"radio"}
                                        onClick={() => changePaymentRecord(index, "paymentMethod", meth.name)}
                                        checked={
                                          paidRecord.paymentMethod === meth.name
                                        }
                                        disabled={disabled}
                                      />
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                          
                          <Form.Group className="col-12">
                            <Form.Label className="black-label-text">
                              Payment Date
                            </Form.Label>
                            <DateInput
                              inputClassName="themeInput"
                              value={paidRecord.date || new Date()}
                              onChange={(newValue) => {
                                changePaymentRecord(
                                  index,
                                  "date",
                                  newValue,
                                );
                              }}
                              shouldDisableDates={true}
                              disabled={disabled}
                            />
                          </Form.Group>

                          <Form.Group className="col-12">
                            <Form.Label className="black-label-text">
                              Enter amount to pay
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                className="hideArrows"
                                name="installmentAmount"
                                placeholder="Write..."
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^-?\d*\.?\d*$/.test(value)) {
                                    setInstallmentAmount(Number(value === "-" ? 0 : value)); // Update state for installmentAmount
                                    changePaymentRecord(index, "currentPaidAmount", value); // Update payment record
                                  }
                                }}
                                value={paidRecord.currentPaidAmount} 
                                disabled={disabled}
                              />
                            </InputGroup>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="black-label-text">
                              Payment Remarks
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                name={"remarks"}
                                as="textarea"
                                rows={2}
                                placeholder="Write..."
                                style={{ resize: "none" }}
                                onChange={(e) => changePaymentRecord(index, "remarks", e.target.value)}
                                value={paidRecord.remarks}
                                disabled={disabled}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </Collapse>
                    </div>
                  )
                })
              }
              
              <div style={{textAlign: "center"}}>
                <Button
                  className="addItem mt-4"
                  onClick={() => {
                    if (patient.isMember && rewardPoints !== 0) {
                      setShowPoint(true);
                    } else {
                      submitForm();
                    }
                  }}
                  style={{ width: "200px", height: "50px", display: "inline-block" }}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </PaymentWrapper>

      <Modal
        centered
        show={showPoint}
        onHide={() => {
          setShowPoint(!showPoint);
        }}
      >
        <Modal.Body>
          <div className="text-center w-100 px-5 py-3">
            <span className="font-600-24 theme">
              This billing will get a reward point
            </span>
          </div>
          <div className="w-100 px-5 ">
            <Form.Group className="w-100">
              <Form.Label className="black-label-text">Points</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  className="hideArrows"
                  name={"rewardPoints"}
                  placeholder="Write..."
                  onChange={(e) => {
                    setRewardPoints(e.target.value);
                  }}
                  value={rewardPoints}
                />
              </InputGroup>
            </Form.Group>
            <div className="d-flex mt-2 justify-content-between">
              {expiryDate && (
                <>
                  <Form.Group className="w-100">
                    <Form.Label className="black-label-text">
                      Expiry Date
                    </Form.Label>
                    <DateInput
                      value={expiryDate}
                      placeholder="Start Date"
                      onChange={(e) => {
                        setExpiryDate(e);
                      }}
                    />
                  </Form.Group>
                </>
              )}
            </div>
          </div>
          <div className="d-flex mt-4">
            <Button
              className="white-button"
              style={{ width: "200px", height: "45px" }}
              onClick={() => {
                setShowPoint(!showPoint);
              }}
            >
              Cancel
            </Button>
            <Button
              className="addItem ms-auto"
              style={{ width: "200px", height: "45px" }}
              onClick={() => {
                setShowPoint(!showPoint);
                submitForm();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddPayment;