import { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { permissionTypes } from "../../../helperFunctions/routes";
import { toastify } from "../../../helperFunctions/toastify";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  exportData,
  getInvoiceId,
} from "../../../helperFunctions/utils";
import { resetValue } from "../../../redux/slices/StaffFormsSlices";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import InvoicePDF from "../../SharedComponents/InvoicePDF";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import FilterComponent from "../../SharedComponents/FilterComponent";
import DateInput from "../../SharedComponents/DateInput";
import { getSetting } from "../../../apis/generalSetting";
import { getAppointment } from "../../../apis/appointment";
import { setPatientData } from "../../../redux/slices/patientSlice";

export default function Billing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [billingData, setBillingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [itemToDel, setItemToDel] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedBilling, setSelectedBilling] = useState({});
  const [stateDate, setStateDate] = useState(tableSearch[fieldPathname]?.currentDate ? tableSearch[fieldPathname]?.currentDate : new Date());
  const [setting, setSetting] = useState(null);
  const [usePointVal, setUsePointVal] = useState(0);

  const onSubmitRedux = (value) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
				currentDate: value
			}
		}))
  }

  const getDefaultFilteredData = (values) => {
		let list = [...values];
		const fieldPathname = pathname.replace("/", "");
	
		if (tableSearch[fieldPathname]) {
			if (tableSearch[fieldPathname].patientName) {
				list = values.filter(v => v.patientName?.toLowerCase()?.includes(tableSearch[fieldPathname].patientName.toLowerCase()));
			}
      if (tableSearch[fieldPathname].invoiceNo) {
				list = values.filter(v => v.invoiceNo?.toString()?.includes(tableSearch[fieldPathname].invoiceNo?.toString()));
			}
		}
	
		return list;
	}

  useEffect(() => {
    getSetting().then((res) => {
      if (res.status !== "Fail") {
        setSetting(res);
      }
    });

    authorizedRequests({
      url: "membershipSetting/listOfMembershipSetting",
      method: "get",
    }).then((res) => {
      setUsePointVal(res.result?.data[0]?.conversion ?? 0);
    });
  }, []);

  const getBillingsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "payment/listOfPayments",
      method: "get",
      params: {
        date: stateDate,
      },
    });
    if (error) {
      toastify(error?.message ?? "Failed to get billing details", "error");
      return;
    }

    const tempArr = result.data.map((o) => {
      return {
        ...o,
        patientName: o?.patient?.patientProfile?.fullName || "No Name",
        tableData: {
          date: new Date(o.date).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }),
          patientId: o.patient?._id,
          id: o._id,
          gst: o?.gst,
          paidAmount:
            o?.paymentRecord && o?.paymentRecord?.length > 0
              ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
              : 0,
          invoice: o.invoiceNo ?? "-",
          name: o?.patient?.patientProfile?.fullName || "No Name",
          amount: o.totalAmount,
          isFree: o.isFree,
          status: getStatusPayment(o),
        },
      };
    });

    setBillingData([...tempArr]);
  };

  const getStatusPayment = (o) => {
    const paidAmount =
      o?.paymentRecord && o?.paymentRecord?.length > 0
        ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
        : 0;
    const gstAmount = o?.gst;

    if (o?.isRefund) {
      if (o?.gst < 0) {
        return "Refund";
      } else if (o?.gst === 0) {
        return "Conversion";
      } else {
        return "Additional Charge";
      }
    }

    if (paidAmount < gstAmount && paidAmount !== 0) {
      return "Outstanding";
    }

    if (paidAmount > gstAmount) {
      return "Overpayment";
    }

    if (o?.isPaymentCompleted === true) {
      return "Paid";
    }

    return "Unpaid";
  };

  const onRowEdit = async (id) => {
    let session = billingData.find((o) => o._id == id);
    if (
      new Date(session.created_at).toLocaleDateString() ===
        new Date().toLocaleDateString() ||
      session.isPaymentCompleted === false
    ) {
      navigate("/add-billing", {
        state: {
          session: session.session,
          branch: session?.branch,
          location: session?.location,
          paymentMethod: session?.paymentMethod,
          patient: session.patient,
          formStatus: "Edit",
          dataId: id,
          totalAmount: session.totalAmount,
          categoryCosts: session.categoryCosts,
          tax: session.tax,
          promo: session.promo,
          promoType: session.promoType,
          usePoints: session.usePoints,
          recommend: session.recommend,
          remarks: session.remarks,
          campaign: session.campaign,
          package: [...session?.package],
          others: [...session?.others],
          paymentAdjustments: [...session?.paymentAdjustments],
          product: [...session?.product],
          treatment: [...session?.treatment],
          rewardPoints: session?.rewardPoints,
          status: session?.isPaymentCompleted,
          remarkDiscount: session?.remarkDiscount,
          isMember: session?.patient?.isMember
        },
      });
    } else {
      return;
    }
  };

  const onPayment = async (id) => {
    let session = billingData.find((o) => o._id == id);
    let lastPaidAmount = 0;
    let lastTotalAmount = session.totalAmount;
    if (session?.paymentRecord?.length > 0) {
      lastPaidAmount = session?.paymentRecord[session?.paymentRecord?.length - 1]?.payed ?? 0;
    }

    navigate("/add-payment", {
      state: {
        dataId: id,
        invoiceNo: session.invoiceNo,
        invoiceDate: new Date(session.created_at).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
        remarks: session.remarks,
        promo: session.promo,
        promoType: session.promoType,
        usePoints: session.usePoints,
        usePointAmount: session.usePointAmount ? session.usePointAmount : 0,
        subtotalAmount: session.actualAmount,
        totalItemAmount: session.totalItemAmount,
        totalAmount: session.totalAmount,
        gstAmount: session.gst,
        patient: session.patient,
        isPaymentInInstallment: session.isPaymentInInstallment,
        isPaymentCompleted: session.isPaymentCompleted,
        paymentRecord: JSON.parse(JSON.stringify(session.paymentRecord)),
        lastPaidAmount: lastPaidAmount,
        lastTotalAmount: lastTotalAmount,
        paymentAdjustments: session.paymentAdjustments,
        package: [...session?.package],
        others: [...session?.others],
        product: [...session?.product],
        treatment: [...session?.treatment],
      },
    });
  };

  const onRowDelete = async (item) => {
    const { result, error } = await authorizedRequests({
      url: "payment/updatePayment",
      method: "post",
      data: {
        dataId: item.id,
        isDeleted: true,
        patient: item.patientId,
      },
    });
    if (error) {
      toastify(error.message ?? "Failed to delete", "error");
      return;
    }
    getBillingsList();
    setFilteredData((prev) => prev.filter((e) => e._id !== item.id));
    toastify("Payment deleted succesfully", "success");
  };

  useEffect(() => {
    getBillingsList();
    dispatch(resetValue("billingForm"));
    dispatch(resetValue("selectedSession"));
  }, [stateDate]);

  const column = [
    // {
    //   name: (
    //     <div className="d-flex align-items-center" id="ovly2">
    //       <span className="me-2">Date</span>
    //       {/* {
    //         <FilterComponent
    //           type="date"
    //           isNested={"tableData"}
    //           array={billingData}
    //           setArray={setFilteredData}
    //           value="date"
    //         />
    //       } */}
    //     </div>
    //   ),
    //   selector: (row) => row.date,
    //   wrap: true,
    //   sortable: true,
    //   sortFunction: (a, b) => customDateSort(a, b, "date"),
    // },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Billing No</span>
          {
            <FilterComponent
              type="search"
              array={billingData}
              setArray={setFilteredData}
              value="invoiceNo"
              searchValue={tableSearch[fieldPathname]?.invoiceNo}
            />
          }
        </div>
      ),
      selector: (row) => {
        return getInvoiceId(row.invoice);
      },
      wrap: true,
      sortable: true,
      // sortFunction: (a, b) => customStringSort(a, b, "invoice"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Patient Name</span>
          {
            <FilterComponent
              type="search"
              array={billingData}
              setArray={setFilteredData}
              value="patientName"
              searchValue={tableSearch[fieldPathname]?.patientName}
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
      cell: (row) => {
        return (
          <div onClick={() => viewPatient((filteredData.length === 0 ? billingData : filteredData)[row.index].patient)}>{row.name}</div>
        )
      }
    },
    {
      name: "Amount",
      selector: (row) => row.amount?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "amount"),
    },
    {
      name: "Amount GST",
      selector: (row) => row.gst?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "gst"),
    },
    {
      name: "Paid Amount",
      selector: (row) => row.paidAmount?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "paidAmount"),
    },
    {
      name: "Status",
      selector: (row) => row.statusButton,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
    },
  ];

  const data = getDefaultFilteredData(billingData)
    ?.map((o, i) => {
      let classNameBtn = "";
      if (o?.tableData?.status === "Paid") {
        classNameBtn = "green-rounded-button";
      } else if (o?.tableData?.status === "Outstanding" || o?.tableData?.status === "Overpayment") {
        classNameBtn = "yellow-rounded-button";
      } else if (o?.tableData?.status === "Additional Charge") {
        classNameBtn = "additional-rounded-button"; 
      } else if (o?.tableData?.status === "Conversion") {
        classNameBtn = "conversion-rounded-button"; 
      } else {
        classNameBtn = "red-rounded-button"; 
      }

      return {
        index: i,
        invoice: o?.tableData?.invoice ?? "-",
        date: o?.tableData?.date,
        id: o?.tableData?.id,
        name: o?.tableData?.name,
        amount: o?.tableData?.amount,
        gst: o?.tableData?.gst ?? "-",
        paidAmount: o?.tableData?.paidAmount ?? 0,
        // isFree: o?.tableData?.isFree ? "True" : "False",
        status: o?.tableData?.status,
        statusButton: (
          <>
            <div className={classNameBtn}>{o.tableData?.status}</div>
          </>
        ),
        action: (
          <div className="d-flex ">
            {o?.tableData?.date ===
              new Date().toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }) && (
              <ComponentWrapepr
                moduleName="Finance"
                permissionType={permissionTypes.UPDATE}
              >
                {(o.isPaymentCompleted === false ||
                  new Date(o.session?.created_at ?? "").toLocaleDateString() ===
                    new Date().toLocaleDateString()) && (
                  <div
                    className="cursor-pointer icon-width"
                    onClick={() => {
                      onRowEdit(o._id);
                    }}
                  >
                    <i className="fa fa-pen"></i>
                  </div>
                )}
              </ComponentWrapepr>
            )}
            {o?.tableData?.date ===
              new Date().toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }) && (
              <ComponentWrapepr
                moduleName="Finance"
                permissionType={permissionTypes.UPDATE}
              >
                {(o.isPaymentCompleted === false ||
                  new Date(o.session?.created_at ?? "").toLocaleDateString() ===
                    new Date().toLocaleDateString()) && (
                  <div
                    className="cursor-pointer icon-width"
                    onClick={() => {
                      onPayment(o._id);
                    }}
                  >
                    <i className="fa fa-credit-card"></i>
                  </div>
                )}
              </ComponentWrapepr>
            )}
            <div
              className="cursor-pointer icon-width"
              onClick={() => {
                setShowInvoice(true);
                setSelectedBilling(o);
              }}
            >
              <i class="fa fa-print print-color"></i>
            </div>
            {o?.tableData?.date ===
              new Date().toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }) && (
              <ComponentWrapepr
                moduleName="Finance"
                permissionType={permissionTypes.DELETE}
              >
                <div
                  className=" cursor-pointer icon-width"
                  onClick={() => {
                    setItemToDel(o?.tableData);
                    setOpenModal(true);
                    // onRowDelete(o?.tableData?.id);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </div>
              </ComponentWrapepr>
            )}
          </div>
        ),
      };
    })
    .reverse();

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const viewPatient = async(patient) => {
    if (patient) {
      dispatch(setPatientData(patient));
      navigate("/add-patient");
    }
  }

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            ></i>

            <span className="400-28-32">List Invoice</span>
          </h1>
          <span className="d-flex">
            <div className="me-3" style={{ width: "200px" }}>
              <DateInput
                value={stateDate}
                placeholder="Date"
                shouldDisableDates={true}
                onChange={(e) => {
                  setStateDate(e);
                  onSubmitRedux(e);
                }}
              />
            </div>
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((o) => {
                  const tempObj = { ...o };
                  delete tempObj.index;
                  delete tempObj.action;
                  delete tempObj.statusButton;
                  return { ...tempObj };
                });
                exportData(tempDataArr, "billing");
              }}
            >
              Export
            </Button>
            <ComponentWrapepr
              moduleName="Finance"
              permissionType={permissionTypes.CREATE}
            >
              <Button
                className="addItem"
                onClick={() => {
                  dispatch(resetValue("billingForm"));
                  navigate("/add-billing", { state: { newBilling: true } });
                }}
              >
                + Add New
              </Button>
            </ComponentWrapepr>
          </span>
        </div>
        <DataTable
          columns={column}
          data={data || []}
          pagination
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      {openModal && (
        <>
          <Modal
            centered
            show={openModal}
            onHide={() => {
              setItemToDel(null);

              setOpenModal(!openModal);
            }}
          >
            <Modal.Body className="font-18">
              Confirm Billing Deletion
              <div className="align-self-end m-3 d-flex">
                <Button
                  className="me-3 delete-no ms-auto"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="delete-yes"
                  onClick={() => {
                    onRowDelete(itemToDel);
                    setOpenModal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}

      {showInvoice && (
        <Modal
          centered
          show={showInvoice}
          onHide={() => {
            setShowInvoice(false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoice
            </Modal.Title>
          </Modal.Header>

          <div className="pdf-btn">
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              <InvoicePDF
                billing={selectedBilling}
                setting={setting}
                usePointVal={usePointVal}
              />
            </PDFViewer>
            {/* <PDFDownloadLink document={<InvoicePDF billing={selectedBilling} />} fileName="Invoice.pdf">
								{({ blob, url, loading, error }) =>
									loading ? 'Loading document...' : 'Download PDF'
								}
							</PDFDownloadLink> */}
          </div>
        </Modal>
      )}
    </>
  );
}
